<template>
  <div class="app-home-wrapper">
    <div class="app-home-inner-wrapper">
      <div class="home-title-text">
        <div class="text-item">Professional and Simple</div>
        <div class="text-item color-green">Al industry</div>
        <div class="text-item">Value investment Database</div>
      </div>
      <div class="home-summary-text">
        A business information service provider committed to the development of
        the Alindustry, discovering high-quality projects and promoting value
        investment
      </div>
      <div class="home-top-search-input">
        <div class="search-input">
          <el-input
            class="home-search-input"
            v-model="searchInputText"
            placeholder="Projects/Investors/Tools"
            style="width: 440px"
          >
          </el-input>
        </div>
        <div class="search-button" @click="goSearchPage">Search</div>
      </div>
      <div class="home-second-part">
        <div class="gap-item-wp">
          <div class="item-up">
            {{ showThousands(topInfo?.data?.project_count) }}
          </div>
          <div class="item-down">Projects</div>
        </div>
        <div class="gap-item-wp">
          <div class="item-up">
            {{ showThousands(topInfo?.data?.investor_count) }}
          </div>
          <div class="item-down">Investors</div>
        </div>
        <div class="gap-item-wp">
          <div class="item-up">
            {{ showThousands(topInfo?.data?.finance_count) }}
          </div>
          <div class="item-down">Fundraising</div>
        </div>
        <div class="gap-item-wp">
          <div class="item-up">
            {{
              `${showThousands(
                convertToBillion(topInfo?.data?.finance_amount)
              )} B`
            }}
          </div>
          <div class="item-down">Total Investment</div>
        </div>
        <!-- <div class="gap-item-wp">
          <div class="item-up">
            {{ showThousands(topInfo?.data?.merge_count) }}
          </div>
          <div class="item-down">M&A</div>
        </div> -->
        <!-- <div class="gap-item-wp">
          <div class="item-up">
            {{
              `${showThousands(
                convertToBillion(topInfo?.data?.merge_amount)
              )} B`
            }}
          </div>
          <div class="item-down">Total M&A</div>
        </div> -->
        <div class="gap-item-wp">
          <div class="item-up">
            {{ showThousands(topInfo?.data?.boss_count) }}
          </div>
          <div class="item-down">People</div>
        </div>
      </div>
      <div class="home-third-part">
        <div class="third-part-title">New Fundransing</div>
        <div class="third-part-table-wrapper" v-loading="newFundransingLoading">
          <el-table
            class-name="home-customize-table"
            :data="newFundransing?.data?.projects || []"
          >
            <el-table-column prop="project" label="Project">
              <template #default="scope">
                <div class="table-project-wrapper">
                  <div class="icon-wrapper">
                    <el-avatar
                      :size="32"
                      :src="scope.row.logo"
                      @error="() => true"
                    >
                      <img src="/images/empty.png" />
                    </el-avatar>
                  </div>
                  <NuxtLink
                    :to="`/projects/detail/${scope.row.code}`"
                    class="text-wrapper animation_underline"
                    >{{ scope.row.name || "--" }}</NuxtLink
                  >
                  <!-- <div class="text-wrapper">
                    {{ scope.row.name || "--" }}
                  </div> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="tag" label="Tag">
              <template #default="scope">
                <div class="table-tag-wrapper">
                  <div
                    class="table-tag-item"
                    v-for="(item, index) in scope.row.tags || []"
                    :key="'tag' + index"
                  >
                    {{ item || "--" }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="round" label="Round">
              <template #default="scope">
                <span>{{ scope.row.round || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="Amount">
              <template #default="scope">
                <span>{{
                  `$${showThousands(convertToMillion(scope.row.amount))}M` ||
                  "--"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="investors" label="Investors" min-width="200">
              <template #default="scope">
                <div class="table-investors-wrapper">
                  <div
                    class="investors-item-wp"
                    v-for="(item, index) in (scope.row?.investor || []).slice(
                      0,
                      2
                    )"
                    :key="'investor' + index"
                  >
                    <div class="icon-wrapper">
                      <el-avatar
                        :size="30"
                        :src="item.logo"
                        @error="() => true"
                      >
                        <img src="/images/empty.png" />
                      </el-avatar>
                    </div>
                    <NuxtLink
                      :to="`/investors/detail/${item.code}`"
                      class="text-wrapper animation_underline"
                      >{{ item.name || "--" }}</NuxtLink
                    >
                    <!-- <div class="text-wrapper">{{ item.name || "--" }}</div> -->
                  </div>
                  <div
                    class="total-count-text"
                    v-if="(scope.row?.investor || []).length > 2"
                  >
                    {{ `${(scope.row?.investor || []).length}` }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="home-four-part">
        <div class="active-investors-wrapper">
          <div class="active-investors-title">Active Investors</div>
          <div class="active-investors-table-wrapper">
            <el-table
              class-name="home-customize-table"
              :data="activeInvestors?.data?.investor || []"
            >
              <el-table-column
                label="Investor"
                fixed="left"
                min-width="280"
              >
                <template #default="scope">
                  <div class="table-project-wrapper">
                    <div class="icon-wrapper">
                      <el-avatar
                        :size="32"
                        :src="scope.row.logo"
                        @error="() => true"
                      >
                        <img src="/images/empty.png" />
                      </el-avatar>
                    </div>
                    <NuxtLink
                      :to="`/investors/detail/${scope.row.code}`"
                      class="text-wrapper animation_underline"
                      >{{ scope.row.name || "--" }}</NuxtLink
                    >
                    <!-- <div class="text-wrapper">
                      {{ scope.row.name || "--" }}
                    </div> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Project"
                min-width="200"
              >
                <template #default="scope">
                  <span>{{ scope.row.project.name || "--" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Amount"
                min-width="200"
              >
                <template #default="scope">
                  <span>{{
                    `$${showThousands(
                      convertToMillion(scope.row.project.amount)
                    )}M` || "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Location"
                min-width="200"
              >
                <template #default="scope">
                  <span>{{ scope.row.project.location || "--" }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="industry-news-wrapper">
          <div class="industry-news-title">Industry News</div>
          <div class="industry-news-content-wrapper">
            <a
              :href="item.url"
              target="_blank"
              class="industry-news-item"
              v-for="(item, index) in news?.data || []"
              :key="'news' + index"
            >
              <div class="news-title">
                {{ item.title }}
              </div>
              <div class="news-content">
                <div class="content-left">
                  <div
                    class="envent-item"
                    v-for="(tag, index) in item.tags || []"
                    :key="'newsTag' + index"
                  >
                    {{ `#${tag}` }}
                  </div>
                </div>
                <div class="content-right">
                  {{ dataFormat(item.create_at * 1000, "YYYY-MM-DD") }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="home-five-part">
        <div class="five-part-title">🔥Hot Project</div>
        <div class="five-part-content">
          <div
            class="content-item-wrapper"
            v-for="(item, index) in popularItems?.data?.projects?.slice(0,4) || []"
            :key="'hot-project' + index"
            @click="goHotsProjectDetail(item)"
          >
            <div class="item-image-wp">
              <el-image :src="item.banner">
                <template #error>
                  <div class="image-slot">
                    <ImageError />
                  </div>
                </template>
              </el-image>
            </div>
            <div class="item-title-wp">
              <div class="title-icon">
                <el-avatar :size="34" :src="item.logo" @error="() => true">
                  <img src="/images/empty.png" />
                </el-avatar>
              </div>
              <div class="title-text">{{ item.name || "--" }}</div>
            </div>
            <div class="item-content-wp">{{ item.bio || "--" }}</div>
          </div>
        </div>
      </div>
      <div class="home-six-part">
        <div class="six-part-left">
          <div class="left-circle">
            <!-- <div class="dot-circle"></div> -->
            <div class="people-item top-people">
              <div class="people-icon-wrap">
                <el-avatar
                  :size="78"
                  src="https://img.rootlist.com/logo/1712302162942016655866.png"
                  @error="() => true"
                >
                  <img src="/images/empty.png" />
                </el-avatar>
              </div>
              <div class="people-name">OpenAI</div>
            </div>
            <div class="people-item left-people">
              <div class="people-icon-wrap">
                <el-avatar
                  :size="78"
                  src="https://img.rootlist.com/logo/1712302226593117603305.png"
                  @error="() => true"
                >
                  <img src="/images/empty.png" />
                </el-avatar>
              </div>
              <div class="people-name">Y Combinator</div>
            </div>
            <div class="people-item bottom-people">
              <div class="people-icon-wrap">
                <el-avatar
                  :size="78"
                  src="https://img.rootlist.com/logo/1712543518741883292956.png"
                  @error="() => true"
                >
                  <img src="/images/empty.png" />
                </el-avatar>
              </div>
              <div class="people-name">Pika</div>
            </div>
            <div class="people-item right-people">
              <div class="people-icon-wrap">
                <el-avatar
                  :size="78"
                  src="https://img.rootlist.com/avatar/1712545245865110212673.png"
                  @error="() => true"
                >
                  <img src="/images/empty.png" />
                </el-avatar>
              </div>
              <div class="people-name">Demi Guo</div>
            </div>
            <div class="hexagon-item left-top">
              <HomeHexagonLogo />
            </div>
            <div class="hexagon-item right-bottom">
              <div class="type-loading">
                <div class="loading-dot"></div>
                <div class="loading-dot"></div>
                <div class="loading-dot"></div>
              </div>
            </div>
            <div class="left-inner-circle">
              <div class="logo-icon"></div>
            </div>
          </div>
        </div>
        <div class="six-part-right">
          <div class="right-part-title">Community Members</div>
          <div class="right-part-summary">
            We are a passionate community dedicated to exploring artificial
            intelligence technology and applications. Whether you want to learn
            more about AI or connect with other AI enthusiasts, our community
            provides an open, supportive, and vibrant platform for you. Join us
            and explore the endless possibilities of AI together!
          </div>
          <a class="join-community-button" href="https://join.slack.com/t/rootlist/shared_invite/zt-2i2vqfl80-t1bi2_6cJD_a5A8nhyFMww" target="_blank">
            <div class="button-text">Join the Community</div>
            <div class="button-icon"></div>
          </a>
        </div>
      </div>
      <div class="home-seven-part">
        <div class="seven-part-title">Thank you for using our product</div>
        <div class="seven-part-summry">
          If you have any suggestions, questions or comments, please feel free
          to contact us via our group chat channel. Our team will be happy to
          communicate with you to ensure our products meet your needs. thank you
          for your support!
        </div>
        <div class="subscribe-wrapper" id="subscribe-wrapper">
          <div class="email-input-wrapper">
            <el-input
              class="home-email-input"
              v-model="emailInputText"
              placeholder="Enter your email address"
              style="width: 318px"
            >
              <template #prefix>
                <EmailIcon />
              </template>
            </el-input>
          </div>
          <div class="subscribe-button" @click="subscribeHandle">Subscribe</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useServerRequest } from "~/composables/useServerRequest";
import { showThousands, convertToBillion, convertToMillion } from "~/utils";
import {
  dataFormat,
  navigateTo,
  useDebounce,
  useHead,
  validEmail,
} from "#imports";
import { ElMessage } from "element-plus";
import { useHttpRequest } from "~/composables/useHttpRequest";

useHead({
  title: "Rootlist - Professional and Simple AI",
});

let searchInputText = ref("");
let emailInputText = ref("");

const { data: topInfo } = await useServerRequest<{ data: any }>(
  "/api/index/stats",
  {
    method: "GET",
  }
);

const { data: popularItems } = await useServerRequest<{ data: any }>(
  "/api/index/hot",
  {
    method: "GET",
  }
);

const { data: newFundransing, pending: newFundransingLoading } =
  await useServerRequest<{ data: any }>("/api/index/startup", {
    method: "GET",
  });

const { data: activeInvestors } = await useServerRequest<{ data: any }>(
  "/api/index/investor",
  {
    method: "GET",
  }
);

const { data: news } = await useServerRequest<{ data: any }>(
  "/api/index/news",
  {
    method: "GET",
  }
);

const subscribeHandle = useDebounce(
  async () => {
    if (!validEmail(emailInputText.value)) {
      process.client &&
        ElMessage.error("Please input the correct email address");
      return;
    }
    useHttpRequest("/api/subscribe", {
      method: "POST",
      body: { email: emailInputText.value },
    })
      .then((res) => {
        console.log(res);
        emailInputText.value = "";
        process.client && ElMessage.success("Subscription successful");
      })
      .catch((err) => {
        console.log(err);
        process.client && ElMessage.error(err.msg || "Subscription failed");
      });
  },
  500,
  {
    leading: false,
    trailing: true,
  }
);

const goHotsProjectDetail = async (item: any) => {
  let code: any = item.code || "";
  if (!code) {
    return;
  }
  await navigateTo(`/projects/detail/${code}`);
};

const goSearchPage = async () => {
  if (!searchInputText.value) {
    return;
  }
  await navigateTo(`/search?keywords=${searchInputText.value}`);
};
</script>
<style lang="scss" scoped>
@keyframes flash-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.5);
  }
  75% {
    transform: scale(2.5);
  }
  78% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes loading-rate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes type-loading {
  75% {
    transform: scale(0.1);
    -webkit-transform: scale(0.1);
  }
}
.table-tag-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .table-tag-item {
    width: fit-content;
    min-width: 26px;
    height: 32px;
    line-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    background: rgba(32, 214, 145, 0.1);
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #20d691;
    margin-left: 8px;
    &:nth-child(1) {
      margin-left: 0 !important;
    }
  }
}
.table-project-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  &:hover {
    .text-wrapper {
      margin-left: 10px;
      &.animation_underline {
        background-position-x: left;
        background-size: 100% 2px;
      }
    }
  }
  .icon-wrapper {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    overflow: hidden;
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .text-wrapper {
    display: block;
    height: 28px;
    line-height: 28px;
    margin-left: 10px;
    color: #333333;
    &.animation_underline {
      background: linear-gradient(90deg, #333333, #333333) no-repeat 100% 100%;
      background-size: 0 2px;
      transition: background-size 0.3s;
    }
  }
}
.table-investors-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .investors-item-wp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      .text-wrapper {
        margin-left: 10px;
        &.animation_underline {
          background-position-x: left;
          background-size: 100% 2px;
        }
      }
    }
    &:nth-child(1) {
      margin-left: 0 !important;
    }
    .icon-wrapper {
      flex-shrink: 0;
      height: 34px;
      width: 34px;
      border-radius: 50%;
      overflow: hidden;
      background: #f3f3f3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text-wrapper {
      display: block;
      height: 28px;
      line-height: 28px;
      margin-left: 10px;
      color: #333333;
      &.animation_underline {
        background: linear-gradient(90deg, #333333, #333333) no-repeat 100% 100%;
        background-size: 0 2px;
        transition: background-size 0.3s;
      }
    }
  }
  .total-count-text {
    flex-shrink: 0;
    margin-left: 20px;
    min-width: 56px;
    height: 32px;
    line-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    background: rgba(32, 214, 145, 0.1);
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #20d691;
  }
}
.image-slot {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 30px;
    width: 30px;
    color: #909399;
  }
}
.app-home-wrapper {
  height: 100%;
  width: 100%;
  padding-top: 148px;
  color: #ffffff;
  box-sizing: border-box;
  padding-bottom: 40px;
  background-color: #0f0f0f;
  background-image: url("/images/home-bg.png");
  background-position: top left;
  background-size: 100% 1014px;
  background-repeat: no-repeat;
  .app-home-inner-wrapper {
    margin: 0 auto;
    width: 1280px;
    .home-title-text {
      text-align: center;
      font-size: 58px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .text-item {
        height: 64px;
        line-height: 64px;
        &.color-green {
          margin-left: 4px;
          margin-right: 4px;
          color: #20d691;
        }
      }
    }
    .home-summary-text {
      margin: 10px auto;
      width: 900px;
      line-height: 28px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #999999;
    }
    .home-top-search-input {
      margin: 112px auto 144px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .search-input {
        margin-right: 10px;
      }
      // .search-button {
      //   margin-left: 10px;
      //   min-width: 82px;
      //   height: 50px;
      //   line-height: 50px;
      //   padding-left: 10px;
      //   padding-right: 10px;
      //   background: linear-gradient(
      //     89deg,
      //     rgba(35, 197, 95, 0.8),
      //     rgba(101, 227, 197, 0.5)
      //   );
      //   border-radius: 29px;
      //   cursor: pointer;
      //   font-weight: 600;
      //   font-size: 14px;
      //   color: #ffffff;
      //   text-align: center;
      // }
      .search-button {
        margin-left: 10px;
        min-width: 82px;
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        padding-right: 10px;
        background: rgba(101, 227, 173, 0.3);
        border-radius: 29px;
        border: 1px solid rgba(35, 197, 95, 1);
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
      }
    }
    .home-second-part {
      margin-top: 100px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      min-height: 108px;
      padding-top: 54px;
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .gap-item-wp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #222222;
        .item-up {
          font-weight: 600;
          font-size: 24px;
          color: #ffffff;
        }
        .item-down {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .home-third-part {
      margin-top: 80px;
      .third-part-title {
        padding-bottom: 20px;
        height: 50px;
        line-height: 30px;
        text-align: left;
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
      }
      .third-part-table-wrapper {
        min-height: 240px;
        background: #ffffff;
        border-radius: 14px;
        border: 1px solid #979797;
        padding: 20px;
        overflow: hidden;
      }
    }
    .home-four-part {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      .active-investors-wrapper {
        width: 880px;
        .active-investors-title {
          height: 50px;
          line-height: 30px;
          font-weight: 600;
          font-size: 26px;
          color: #ffffff;
          text-align: left;
          padding-bottom: 20px;
        }
        .active-investors-table-wrapper {
          background: #ffffff;
          border-radius: 14px;
          min-height: 240px;
          height: calc(100% - 50px);
          overflow-x: hidden;
          overflow-y: auto;
          border: 1px solid #979797;
          border-bottom: 20px solid #ffffff;
          padding: 20px 20px 0 20px;
        }
      }
      .industry-news-wrapper {
        width: 360px;
        .industry-news-title {
          height: 50px;
          line-height: 30px;
          font-weight: 600;
          font-size: 26px;
          color: #ffffff;
          text-align: left;
          padding-bottom: 20px;
        }
        .industry-news-content-wrapper {
          background: #ffffff;
          border-radius: 14px;
          min-height: 240px;
          height: calc(100% - 50px);
          overflow-x: hidden;
          overflow-y: auto;
          border: 1px solid #979797;
          padding: 10px;
          .industry-news-item {
            padding-left: 10px;
            padding-right: 10px;
            display: block;
            min-height: 104px;
            border-bottom: 1px solid rgba(221, 221, 221, 0.5);
            padding-bottom: 24px;
            padding-top: 20px;
            border-radius: 6px;
            cursor: pointer;
            &:hover {
              background: #f3f3f3;
              border-bottom: 1px solid transparent !important;
            }
            .news-title {
              line-height: 28px;
              font-weight: 600;
              font-size: 18px;
              color: #333333;
            }
            .news-content {
              padding-top: 14px;
              display: flex;
              justify-content: space-between;
              align-content: center;
              .content-left {
                display: grid;
                grid-auto-flow: column;
                gap: 10px;
                align-items: center;
                .envent-item {
                  min-width: 50px;
                  padding-left: 10px;
                  padding-right: 10px;
                  height: 28px;
                  line-height: 28px;
                  background: rgba(32, 214, 145, 0.1);
                  border-radius: 6px;
                  font-weight: 400;
                  font-size: 14px;
                  color: #20d691;
                }
              }
              .content-right {
                flex-shrink: 0;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
              }
            }
          }
        }
      }
    }
    .home-five-part {
      margin-top: 40px;
      .five-part-title {
        height: 50px;
        line-height: 30px;
        text-align: left;
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
        padding-bottom: 20px;
      }
      .five-part-content {
        min-height: 400px;
        // display: flex;
        // justify-content: flex-start;
        // align-items: stretch;
        display: inline-grid;
        grid-auto-flow: column;
        gap: 32px;
        align-items: stretch;
        .content-item-wrapper {
          min-height: 390px;
          width: 298px;
          background: #ffffff;
          box-shadow: 0px 8px 12px 0px rgba(13, 37, 24, 0.1);
          border-radius: 14px;
          border: 1px solid #ebedf1;
          padding: 16px;
          cursor: pointer;
          .item-image-wp {
            width: 266px;
            height: 184px;
            background: #f3f3f3;
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .item-title-wp {
            margin-top: 10px;
            height: 54px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .title-icon {
              height: 34px;
              width: 34px;
              border-radius: 50%;
              background: #f3f3f3;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .title-text {
              margin-left: 10px;
              font-weight: 600;
              font-size: 22px;
              color: #222222;
              text-align: left;
            }
          }
          .item-content-wp {
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            text-align: left;
            line-height: 20px;
          }
        }
      }
    }
    .home-six-part {
      margin-top: 20px;
      padding-top: 100px;
      padding-bottom: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .six-part-left {
        height: 590px;
        width: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        .left-circle {
          width: 420px;
          height: 420px;
          border: 5px solid rgba(67, 237, 196, 0.2);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          // animation: loading-rate 8s infinite linear;
          // animation-delay: 200ms;
          // transition: 0.5s all ease;
          // transform: loading-rate(1);
          .dot-circle {
            position: absolute;
            right: 16px;
            top: 300px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: rgba(32, 214, 145, 0.8);
            &::before {
              position: absolute;
              content: "";
              height: 16px;
              width: 16px;
              left: 0;
              top: 0;
              background-color: #eed968;
              border-radius: 50%;
              opacity: 0.7;
              animation: flash-scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
              animation-delay: 200ms;
              transition: 0.5s all ease;
              transform: flash-scale(1);
            }
          }
          .people-item {
            height: 139px;
            width: 138px;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            &.top-people {
              left: 50%;
              top: -57px;
              transform: translateX(-50%);
            }
            &.left-people {
              left: 0;
              top: 50%;
              transform: translate(-60%, -50%);
            }
            &.bottom-people {
              left: 50%;
              bottom: 0;
              transform: translate(-50%, 84%);
            }
            &.right-people {
              right: 0;
              top: 50%;
              transform: translate(60%, -50%);
            }
            .people-icon-wrap {
              width: 78px;
              height: 78px;
              border-radius: 50%;
              border: 3px solid #20d691;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              // background: rgba(32, 214, 145, 0.2);
              // background: url("public/images/people-test.jpeg");
              // background-size: 100% 100%;
              // background-position: center center;
              // background-repeat: no-repeat;
            }
            .people-name {
              margin-top: 15px;
              width: 138px;
              height: 46px;
              line-height: 46px;
              background: #222222;
              border-radius: 7px;
              border: 1px solid #232c33;
              text-align: center;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
            }
          }
          .hexagon-item {
            height: 78px;
            width: 78px;
            position: absolute;
            background: url("public/images/hexagon.svg");
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
            color: #FFFFFF;
            &.left-top {
              left: 0;
              top: -40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &.right-bottom {
              right: 0;
              bottom: -40px;
              display: flex;
              justify-content: center;
              align-items: center;
              .type-loading {
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                .loading-dot {
                  margin-left: 2px;
                  margin-right: 2px;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: rgba(255, 255, 255, 0.8);
                  &:nth-child(1) {
                    -webkit-animation: type-loading 2s infinite;
                    animation: type-loading 2s infinite;
                  }
                  &:nth-child(2) {
                    -webkit-animation: type-loading 2s infinite;
                    animation: type-loading 2s infinite;
                    -webkit-animation-delay: 0.25s;
                    animation-delay: 0.25s;
                  }
                  &:nth-child(3) {
                    -webkit-animation: type-loading 2s infinite;
                    animation: type-loading 2s infinite;
                    -webkit-animation-delay: 0.5s;
                    animation-delay: 0.5s;
                  }
                }
              }
            }
          }
          .left-inner-circle {
            height: 274px;
            width: 274px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 10px 12px 50px 0px rgba(32, 214, 145, 0.2);
            // border: 35px solid linear-gradient(312deg, rgba(67, 237, 196, 1), rgba(30, 193, 116, 1));
            border: 35px solid rgba(30, 193, 116, 1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .logo-icon {
              height: 111px;
              width: 144px;
              background: url("public/images/left-inner-logo.png");
              background-size: 100% 100%;
              background-position: center center;
              background-repeat: no-repeat;
            }
          }
        }
      }
      .six-part-right {
        margin-left: 90px;
        width: 460px;
        min-height: 200px;
        .right-part-title {
          font-weight: 600;
          font-size: 36px;
          color: #ffffff;
          line-height: 30px;
          text-align: left;
        }
        .right-part-summary {
          margin-top: 20px;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          line-height: 20px;
          text-align: left;
        }
        .join-community-button {
          margin-top: 20px;
          min-width: 234px;
          width: fit-content;
          height: 58px;
          padding-left: 20px;
          padding-right: 20px;
          background: rgba(101, 227, 173, 0.2);
          border-radius: 12px;
          border: 1px solid rgba(35, 197, 95, 1);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .button-text {
            font-weight: 600;
            font-size: 18px;
            color: #20d691;
            text-align: center;
            margin-right: 3px;
          }
          .button-icon {
            margin-left: 3px;
            height: 15px;
            width: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .home-seven-part {
      min-height: 314px;
      background: #222222;
      border-radius: 20px;
      border: 1px solid #232c33;
      padding: 50px 200px;
      text-align: center;
      .seven-part-title {
        font-weight: 600;
        font-size: 36px;
        color: #ffffff;
      }
      .seven-part-summry {
        margin-top: 20px;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
      }
      .subscribe-wrapper {
        margin-top: 46px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .email-input-wrapper {
          margin-right: 9px;
        }
        .subscribe-button {
          margin-left: 9px;
          min-width: 126px;
          height: 50px;
          line-height: 50px;
          padding-left: 20px;
          padding-right: 20px;
          background: rgba(101, 227, 173, 0.2);
          border-radius: 12px;
          border: 1px solid rgba(35, 197, 95, 1);
          font-weight: 600;
          font-size: 18px;
          color: #20d691;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
