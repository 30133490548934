<template>
  <svg
    width="40px"
    height="28px"
    viewBox="0 0 40 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stop-color="#FFFFFF" stop-opacity="0.6" offset="0%"></stop>
        <stop stop-color="#FFFFFF" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="indexon"
        transform="translate(-445.000000, -3315.000000)"
        fill="url(#linearGradient-1)"
        fill-rule="nonzero"
      >
        <g id="feedback" transform="translate(364.000000, 3241.000000)">
          <g id="bz-19" transform="translate(63.000000, 50.000000)">
            <g id="group_senior" transform="translate(18.000000, 24.000000)" fill="#FFFFFF">
              <path
                d="M24.0012105,9 C24.0012105,6.23857625 26.2404642,4 29.0027236,4 C31.764983,4 34.0042367,6.23857625 34.0042367,9 C34.0042367,11.7614237 31.764983,14 29.0027236,14 C26.2404642,14 24.0012105,11.7614237 24.0012105,9 L24.0012105,9 Z M13.9981843,12 C17.3128956,12 20,9.3137085 20,6 C20,2.6862915 17.3128956,0 13.9981843,0 C10.683473,0 7.99636851,2.6862915 7.99636851,6 C7.99636851,9.3137085 10.683473,12 13.9981843,12 Z M24.9014829,19.32 C23.8835801,17.2816848 21.798747,15.9955227 19.5198547,15.9999883 L8.47651377,15.9999883 C6.19762152,15.9955227 4.11278843,17.2816848 3.09488566,19.32 L0.214014099,25.1 C-0.0980187912,25.7190365 -0.0671614854,26.4554557 0.295564917,27.0462357 C0.658291319,27.6370156 1.30115658,27.9979022 1.99455277,28 L26.0018157,28 C26.6952119,27.9979022 27.3380772,27.6370156 27.7008036,27.0462357 C28.06353,26.4554557 28.0943873,25.7190365 27.7823544,25.1 L24.9014829,19.32 Z M39.7859859,25.1 L37.8854109,21.3 C36.8651532,19.2759028 34.7910284,17.9993093 32.5237889,17.9999997 L28.7026328,17.9999997 L31.3634378,23.32 C32.0944116,24.774704 32.2027261,26.4638994 31.6635286,27.9999997 L38.0054472,27.9999997 C38.6988434,27.9979022 39.3417087,27.6370156 39.7044351,27.0462357 C40.0671615,26.4554557 40.0980188,25.7190365 39.7859859,25.1 Z"
                id="Icon-color"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
